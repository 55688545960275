import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";
import useEmitter from "@/Composables/useEmitter";
import TheLoginForm from "@/Components/App/forms/TheLoginForm.vue";
import TheVerificationMessage from "@/Components/App/notifications/TheVerificationMessage.vue";
import AppArticleProp from "@/Components/Shared/article/AppArticleProp.vue";
import { parse, isValid, format } from "date-fns";
import { nlBE } from "date-fns/locale";
import {SelectSize} from "@/Components/Shared/forms/AppFormMultiSelect.vue";

const { emitter } = useEmitter();
export const apiPathUrl = (path: any) => `/api${path}`;

// checks
export const isLoggedIn = computed(
    () => usePage().props?.tcg?.isLoggedIn || false,
);
export const isVerified = computed(
    () => usePage().props?.tcg?.isVerified || false,
);

export const isGuest = computed(() => usePage().props?.tcg?.isGuest || false);

export const isAdmin = computed(() => usePage().props?.tcg?.isAdmin || false);

export const is2FA = computed(() => usePage().props?.tcg?.is2FA || false);
export const authUser = computed(() => usePage().props?.tcg?.authUser || null);

export const isCurrentUserById = userId => {
    return authUser.value && authUser.value.data
        ? authUser.value.data.id === userId
        : false;
};

export const mustBeVerified = () => {
    emitter.emit("modal", {
        component: TheVerificationMessage,
        title: "Je account moet geactiveerd zijn om dit te kunnen doen",
        closeOnClick: false,
    });
};

export const mustBeLogin = () => {
    emitter.emit("modal", {
        component: TheLoginForm,
        props: {
            withRecaptcha: false,
        },
        title: "",
        alert: "Je moet ingelogd zijn om dit te kunnen doen",
        closeOnClick: false,
    });
};

export const mustBeOwner = () => {
    emitter.emit("modal", {
        component: AppArticleProp,
        props: {
            text: "Je bent niet de eigenaar van dit item en kunt deze actie dus niet uitvoeren",
        },
    });
};

export const truncate = (string: any, amount = 200) => {
    if (!string) {
        return string;
    }

    if (Array.isArray(string)) {
        string = JSON.stringify(string);
    }

    if (string.length > amount) return string.substring(0, amount) + "...";
    else return string;
};

export const scrollIntoViewWithOffset = (element: any, offset: any) => {
    if (typeof window !== "undefined" && element) {
        element.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    }
};

export const getFormattedDate = (date, format = "dd/MM/yyyy") => {
    const parsed = parse(date, format, new Date());
    if (isValid(parsed)) {
        return parsed;
    }
    return null;
};

export const formatDate = (date, formatString = "dd/MM/yyyy") => {
    return format(getFormattedDate(date), formatString, {locale: nlBE });
};

export const getFormatDate = (date) => {
    return formatDate(date, 'd LLLL yyyy');
};

export function htmlDecode(input: string) {
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
}

export const subjectsShortcodesFn = (subjects) => {
    return subjects.reduce((acc, subject) => {
        if (subject.type === "card") {
            if (subject.name && !subject.general) {
                acc.push(`[[${subject.name} variant="${subject.id}"]]`);
            } else if (subject.name) {
                acc.push(`[[${subject.name}]]`);
            }
        }
        return acc;
    }, []);
};

export const multiselectClasses = {
    container: 'relative text-slate-700 mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-slate-300 bg-white text-base leading-snug outline-none',
    containerDisabled: 'cursor-default bg-gray-100',
    containerOpen: 'rounded-b-none',
    containerOpenTop: 'rounded-t-none',
    containerActive: 'ring ring-green-500 ring-opacity-30',
    wrapper: 'relative mx-auto h-full w-full flex items-center justify-end box-border cursor-pointer outline-none',
    singleLabel: 'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-2 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
    singleLabelText: 'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
    multipleLabel: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-2 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
    search: 'w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white rounded pl-2 rtl:pl-0 rtl:pr-3.5',
    tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2 min-w-0 rtl:pl-0 rtl:pr-2',
    tag: 'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap min-w-0 rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
    tagDisabled: 'pr-2 opacity-50 rtl:pl-2',
    tagWrapper: 'whitespace-nowrap overflow-hidden overflow-ellipsis',
    tagWrapperBreak: 'whitespace-normal break-all',
    tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
    tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
    tagsSearchWrapper: 'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
    tagsSearch: 'absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-base font-sans box-border w-full',
    tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
    placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-2 text-gray-400 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
    caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5',
    caretOpen: 'rotate-180 pointer-events-auto',
    clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5',
    clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
    spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0 rtl:mr-0 rtl:ml-3.5',
    infinite: 'flex items-center justify-center w-full',
    infiniteSpinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 animate-spin flex-shrink-0 flex-grow-0 m-3.5',
    dropdown: 'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
    dropdownTop: '-translate-y-full top-px bottom-auto rounded-b-none rounded-t',
    dropdownHidden: 'hidden',
    options: 'flex flex-col p-0 m-0 list-none',
    optionsTop: '',
    group: 'p-0 m-0',
    groupLabel: 'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal',
    groupLabelPointable: 'cursor-pointer',
    groupLabelPointed: 'bg-gray-300 text-gray-700',
    groupLabelSelected: 'bg-green-600 text-white',
    groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
    groupLabelSelectedPointed: 'bg-green-600 text-white opacity-90',
    groupLabelSelectedDisabled: 'text-green-100 bg-green-600 bg-opacity-50 cursor-not-allowed',
    groupOptions: 'p-0 m-0',
    option: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
    optionPointed: 'text-gray-800 bg-gray-100',
    optionSelected: 'text-white bg-green-500',
    optionDisabled: 'text-gray-300 cursor-not-allowed',
    optionSelectedPointed: 'text-white bg-green-500 opacity-90',
    optionSelectedDisabled: 'text-green-100 bg-green-500 bg-opacity-50 cursor-not-allowed',
    noOptions: 'py-2 px-3 text-gray-600 bg-white text-left rtl:text-right',
    noResults: 'py-2 px-3 text-gray-600 bg-white text-left rtl:text-right',
    fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
    assist: 'absolute -m-px w-px h-px overflow-hidden',
    spacer: 'h-9 py-px box-content'
}
